import React from 'react';
import { ContentMediaListBlock } from 'patterns/ContentMediaListBlock';
import { BlogList } from 'patterns/BlogList';
import { BookListBlock } from 'patterns/BookListBlock';
import { Masthead } from 'patterns/Masthead';
import { Shell } from 'patterns/Shell';
import { SubHeader } from 'patterns/SubHeader';
import { TechBlock } from 'patterns/TechBlock';
import { ProductBlock } from 'patterns/ProductBlock';

import '../scss/main.scss';

function HealthLandingPage({
    location: {
        pathname,
    },
}) {
    const data = {
        masthead: {
            title: 'Health',
            description: [
                {
                    spans: [],
                    text: 'Some of my favourite products and tools I use to lead a healthy – hopefully long life.',
                    type: 'paragraph',
                },
            ],
            image: {
                alt: '',
                dimensions: {
                    height: 1510,
                    width: 1920,
                },
                url: 'https://images.prismic.io/robsimpson/d98d1321-4a6a-4d9a-ae7f-f0effa37d38d_health-masthead.jpg?auto=compress,format'
            },
        },
        blog: {
            items: [
                {
                    card_description: 'Reflecting on 2020 – a year that included the most change in my life personally and professionally for a long time.',
                    card_image: {
                        alt: '',
                        dimensions: {
                            height: 1024,
                            width: 768,
                        },
                        url: 'https://images.prismic.io/robsimpson/2f4a907b-376a-4174-8762-734975b18bd1_about-zoo.png?auto=compress,format'
                    },
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'articles/2020-year-in-review',
                    },
                    card_title: {
                        text: '2020 year in review',
                    },
                },
            ],
        },
        exercise: {
            overline: 'Exercise',
            title: 'Using exercise not to just look better, but perform better in life and in business',
            content: [
                {
                    spans: [],
                    text: 'We all know the importance of exercise – but sometimes we need reminding (myself included). The key is to find something you enjoy.',
                    type: 'paragraph',
                },
                {
                    spans: [],
                    text: 'For me a low barrier activity I like is walking or cycling, it means I get fresh air and sunlight. Weight training on the other hand is a much more difficult activity to start – but having a small home gym helps to reduce the friction of getting started.',
                    type: 'paragraph',
                },
            ],
            media:[
                {
                    media_image: {
                        alt: '',
                        dimensions: {
                            height: 1242,
                            width: 2484,
                        },
                        url: 'https://images.prismic.io/robsimpson/83437f92-3c1b-49e2-a046-eaf008e2f321_gym.jpg?auto=compress,format'
                    },
                },
            ],
            products: [
                {
                    product_brand: 'Apple',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/35b9156d-29b6-4414-9d33-bfcafd111b3a_applewatch.jpg?auto=compress,format'
                    },
                    product_name: 'Smart watch',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B08J6MVVKR/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B08J6MVVKR&linkCode=as2&tag=robsimpson04-21&linkId=0297140598a2022f43cf4a27cbcdc156',
                    },
                },
                {
                    product_brand: 'Gymshark',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/04066c5e-7100-4c91-9527-ad3a7c543ee3_hoody.jpg?auto=compress,format'
                    },
                    product_name: 'Hoody',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://uk.gymshark.com/products/gymshark-critical-zip-hoodie-black',
                    },
                },
                {
                    product_brand: 'Gymshark',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/b1471601-dbe7-420c-9136-1ecf0186a8eb_tshirt.jpg?auto=compress,format'
                    },
                    product_name: 'T-shirt',
                },
                {
                    product_brand: 'Gymshark',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/26c0664a-72a3-44e9-b98b-e7f87f8ecf59_joggers.jpg?auto=compress,format'
                    },
                    product_name: 'Joggers',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://uk.gymshark.com/products/gymshark-tapered-bottoms-black',
                    },
                },
                {
                    product_brand: 'Bulldog Gear',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/4ef841cb-b7f4-4dc5-9dcc-2c87dc6a0430_weightbench.jpg?auto=compress,format'
                    },
                    product_name: 'Weight bench',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://bulldoggear.com/collections/adjustable-benches/products/adjustable-bench-2-0',
                    },
                },
                {
                    product_brand: 'Wolverson Fitness',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/344d880c-0e6f-4b50-9c25-dd566e4caa94_foldingrack.jpg?auto=compress,format'
                    },
                    product_name: 'Folding rack',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://wolverson-fitness.co.uk/products/folding-squat-rack',
                    },
                },
                {
                    product_brand: 'Bulldog Gear',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/f0c482fb-e61e-4d29-b66b-32903541f43b_dumbbell.jpg?auto=compress,format'
                    },
                    product_name: 'Dumbbells',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://bulldoggear.com/collections/dumbbells/products/hex-dumbbells-2-0',
                    },
                },
                {
                    product_brand: 'Isolift',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/6c509f92-3897-493e-85b6-09005fe135cf_weightplates.jpg?auto=compress,format'
                    },
                    product_name: 'Weight plates',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.isolift.co.uk/collections/weight-plates/products/20kg-steel-weights',
                    },
                },
                {
                    product_brand: 'Isolift',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/4b5386be-5ab3-4cc4-a6b4-8cb6d002ad8b_barbell.jpg?auto=compress,format'
                    },
                    product_name: 'Barbell',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.isolift.co.uk/collections/barbells/products/7ft-olympic-barbell-fixed',
                    },
                },
                {
                    product_brand: 'Wolverson Fitness',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/8c9ceb11-fd88-42c0-aeaf-39af671d6ff6_flooring.jpg?auto=compress,format'
                    },
                    product_name: 'Flooring',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://wolverson-fitness.co.uk/collections/flooring/products/30mm-epdm-rubber-flooring',
                    },
                },
            ],
        },
        supplements: {
            overline: 'Supplements',
            title: 'Living more focused, healthier days through quality supplements',
            content: [
                {
                    spans: [],
                    text: 'I used to be somewhat sceptical about taking supplements – until I did some reading. The problem is, most supplements are filled with useless ingredients or not enough of the right stuff.',
                    type: 'paragraph',
                },
                {
                    spans: [],
                    text: 'In the beginning, it was hard to justify paying extra for these supplements over cheaper ones – but once I saw it as an investment in myself, it became a no-brainer.',
                    type: 'paragraph',
                },
            ],
            media:[
                {
                    media_image: {
                        alt: '',
                        dimensions: {
                            height: 1242,
                            width: 2484,
                        },
                        url: 'https://images.prismic.io/robsimpson/deafa974-c5bc-4d78-b056-5f72989f9f96_supplements.jpg?auto=compress,format'
                    },
                },
            ],
            products: [
                {
                    product_brand: 'Feel',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/c8bf4c00-3583-44b8-93fc-6e10ea5e767d_multivitamin.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Multivitamin',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://wearefeel.com/products/essential-multivitamin',
                    },
                },
                {
                    product_brand: 'Feel',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/de2fb2fe-9b86-468e-92ad-de6666106d82_omega3.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Omega 3',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://wearefeel.com/products/feel-algae-omega-3',
                    },
                },
                {
                    product_brand: 'Vivo',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/1c0dc369-8b08-456b-a566-d10f17d130c4_proteinpowder.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Protein powder',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B07QCQQPMR/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B07QCQQPMR&linkId=8eba2d25ddeb75b70bbf31d0e1b20a3f',
                    },
                },
                {
                    product_brand: 'Vivo',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/dd6373e9-2350-4946-a3c1-ae37ac1ea0d1_creatine.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Creatine',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B083V74V3J/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B083V74V3J&linkId=c986b1c792182c127713dee73c1b2275',
                    },
                },
            ],
        },
        drinks: {
            overline: 'Drinks',
            title: 'Cus’ we all need to drink a little more water and a little less coffee',
            content: [
                {
                    spans: [],
                    text: 'Want to drink more water? I swear by the BLACK+BLUM water jug paired with the Chilly water bottle. I like water that is cold and doesn’t taste weird – these 2 together do exactly that.',
                    type: 'paragraph',
                },
                {
                    spans: [],
                    text: 'When it comes to coffee I have a hard limit of 2 per day and will not touch it after 2 in the afternoon (unless I don’t want to sleep at night, but that’s what kids are for).',
                    type: 'paragraph',
                },
            ],
            media:[
                {
                    media_image: {
                        alt: '',
                        dimensions: {
                            height: 1242,
                            width: 2484,
                        },
                        url: 'https://images.prismic.io/robsimpson/eff181ce-4e53-4311-9291-90de006c67c5_drinks.jpg?auto=compress,format'
                    },
                },
            ],
            products: [
                {
                    product_brand: 'Chilly',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/753875e5-387f-43fd-b566-1797267e965c_waterbottle.jpg?auto=compress,format'
                    },
                    product_name: 'Water bottle',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B08YNZG5J9/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B08YNZG5J9&linkId=a5de4feaa6ace7c0c7e0a73843d46d5f',
                    },
                },
                {
                    product_brand: 'Chilly',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/d990d8bf-c17c-4aab-bceb-991d2f170039_coffeecup.jpg?auto=compress,format'
                    },
                    product_name: 'Coffee cup',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B08YNZHPVT/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B08YNZHPVT&linkId=0e7ee33684baee90ac3dc4fda1252800',
                    },
                },
                {
                    product_brand: 'Vivo',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/007ef3f1-3c70-429f-b824-74004bb1d248_proteinshaker.jpg?auto=compress,format'
                    },
                    product_name: 'Protein shaker',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B09B128819/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B09B128819&linkId=22e51648faaa68c61e73b6729b35146f',
                    },
                },
                {
                    product_brand: 'BLACK + BLUM',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/5c674e33-273a-4f78-bd1f-38497be05541_glasswaterjug.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Glass water jug',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B07QFS537M/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B07QFS537M&linkId=ddf7490ddb75d9462a1e3d07f5076d78',
                    },
                },
                {
                    product_brand: 'Phox',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/9999687e-c998-4e49-93d8-4a6d5bda7c36_waterfilter.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Water filter',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B08371R9TK/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B08371R9TK&linkId=bc4f4d43d28b378b4d8d06fe92b12068',
                    },
                },
            ],
        },
        hygiene: {
            overline: 'Hygiene',
            title: 'Better for the planet and better for your body',
            content: [
                {
                    spans: [],
                    text: 'Choosing more environmentally friendly products is a must for me – but they often come with the side effect of being more natural products with fewer chemicals etc. in them, meaning they’re better for you.',
                    type: 'paragraph',
                },
                {
                    spans: [],
                    text: 'It’s taken me some time to switch over to these – some take some getting used to (like the toothpaste), but they quickly become the norm if you stick with them.',
                    type: 'paragraph',
                },
            ],
            media:[
                {
                    media_image: {
                        alt: '',
                        dimensions: {
                            height: 1242,
                            width: 2484,
                        },
                        url: 'https://images.prismic.io/robsimpson/74c197bf-0aa2-4722-9d12-b16bbcbedb29_hygiene.jpg?auto=compress,format'
                    },
                },
            ],
            products: [
                {
                    product_brand: 'Wild',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/fa754b81-acc4-4280-a385-b90e620456aa_deodorant.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Deodorant',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.wearewild.com/',
                    },
                },
                {
                    product_brand: 'Georganics',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/f779d4c9-5aa8-4324-8782-cd6d7929d7ff_toothpaste.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Toothpaste',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B019CWUAD8/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B019CWUAD8&linkId=6ddbe8ef5c7ef12695b2aca1647d4842',
                    },
                },
                {
                    product_brand: 'Georganics',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/f9c0dfc6-5292-4ea9-8e81-48f51eb0d418_mouthwash.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Mouthwash',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B0752RLDXC/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B0752RLDXC&linkId=b3f5aeff0fff12226469bb2bc68dbcb1',
                    },
                },
                {
                    product_brand: 'nuud',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/b2b81b1f-ab6d-493e-9df1-786c7b83b224_chewinggum.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Chewing gum',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B091YTSDD1/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B091YTSDD1&linkId=0f0c751d424519b533ffd2d5fd531d3b',
                    },
                },
                {
                    product_brand: 'gruum',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/83fa46b4-2ee3-460d-bf0c-71adf56a674d_shampoobar.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Shampoo bar',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.gruum.com/product/har-revitalising/',
                    },
                },
                {
                    product_brand: 'gruum',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/2f3f1e2a-392b-4c99-a6ef-cce44ca4826e_conditionerbar.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Conditioner bar',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.gruum.com/products-glos-conditioner-bar/',
                    },
                },
                {
                    product_brand: 'gruum',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/9650e0bd-ee6d-4048-a8c5-79a367debe48_bodybar.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Body bar',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.gruum.com/products-sapa-body-bars/',
                    },
                },
                {
                    product_brand: 'Rugged Nature',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/1565e3d2-241c-483d-a449-378a4f653a39_hairwax.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Hair wax',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://ruggednature.co.uk/collections/hair-and-body-products/products/cedarwood-hair-wax',
                    },
                },
                {
                    product_brand: 'smol',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/d18aeb99-f024-4031-a9f7-d35f8458151c_laundrycapsules.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Laundry capsules',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://smolproducts.com/pages/laundry',
                    },
                },
                {
                    product_brand: 'smol',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/9bd4ec25-4bd1-47e4-ab64-9402c7998145_fabricconditioner.jpg?auto=compress,format'
                    },
                    product_label: 'Eco-friendly',
                    product_name: 'Fabric conditioner',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://smolproducts.com/pages/fabcon',
                    },
                },
            ],
        },
        cooking: {
            overline: 'Cooking',
            title: 'Cooking my way to a healthier, hopefully, longer life',
            content: [
                {
                    spans: [],
                    text: 'I grew up in a household where cooking every day was normal. Although I never did the cooking myself, I took that idea with me and started cooking properly when I finished university –  as I saw it as the cornerstone to a healthy life.',
                    type: 'paragraph',
                },
                {
                    spans: [],
                    text: 'I’m a mediocre cook, but I find the act of cooking quite relaxing and enjoyable, paired with it being healthy and saving me money is a win-win.',
                    type: 'paragraph',
                },
            ],
            media:[
                {
                    media_image: {
                        alt: '',
                        dimensions: {
                            height: 1242,
                            width: 2484,
                        },
                        url: 'https://images.prismic.io/robsimpson/417dac70-9b7d-4202-afb3-1a31f31b7c05_kitchen.jpg?auto=compress,format'
                    },
                },
            ],
            products: [
                {
                    product_brand: 'Robert Welch',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/e798c375-cb50-478c-9ff3-4f875ac1b5f4_chefsknife.jpg?auto=compress,format'
                    },
                    product_name: 'Chefs knife',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Robert-Welch-Signature-Cooks-Knife/dp/B00CUDDCRS/',
                    },
                },
                {
                    product_brand: 'Robert Welch',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/9861f1fb-c3a2-40ef-a91b-33ac756ac8be_pairingknife.jpg?auto=compress,format'
                    },
                    product_name: 'Pairing knife',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://amazon.co.uk/Robert-Welch-Signature-Vegetable-Knife/dp/B003XEMTCW/',
                    },
                },
                {
                    product_brand: 'Denby',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/e63ebcba-c284-474d-bb0e-3fda1588e7f6_wok.jpg?auto=compress,format'
                    },
                    product_name: 'Wok',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.denbypottery.com/bestsellers/wok/191013478',
                    },
                },
                {
                    product_brand: 'Denby',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/43219a34-f1d9-4e56-983e-0e6cd9be56cf_largefryingpan.jpg?auto=compress,format'
                    },
                    product_name: 'Large fryingpan',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.denbypottery.com/bestsellers/fry-pan/191013476',
                    },
                },
                {
                    product_brand: 'Denby',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/43e3c4da-c3c7-42ee-ada8-839b53a8c7dd_smallfryingpan.jpg?auto=compress,format'
                    },
                    product_name: 'Small fryingpan',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.denbypottery.com/bestsellers/fry-pan/191013475',
                    },
                },
                {
                    product_brand: 'Denby',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/0aee2bbd-6b8b-4d79-88c0-9fd8bd3e2a23_largesaucepan.jpg?auto=compress,format'
                    },
                    product_name: 'Large saucepan',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.denbypottery.com/cast-aluminium-pans/saucepan/191013410',
                    },
                },
                {
                    product_brand: 'Denby',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/c82697d1-f830-438c-a42a-7eace0b395c9_smallsaucepan.jpg?auto=compress,format'
                    },
                    product_name: 'Small saucepan',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.denbypottery.com/bestsellers/saucepan/191013480',
                    },
                },
                {
                    product_brand: 'Cole & Mason',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/4f570fde-70da-43b1-ac0f-51aaa22dc313_pestlemortar.jpg?auto=compress,format'
                    },
                    product_name: 'Pestle & mortar',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/gp/product/B00CFGMV0E/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=robsimpson04-21&creative=6738&linkCode=as2&creativeASIN=B00CFGMV0E&linkId=1e3dcdd4a177e4cb815d03070cbb4878',
                    },
                },
                {
                    product_brand: 'OXO',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/bb8c42eb-7ed8-479f-9387-25f60fa5a373_saladspinner.jpg?auto=compress,format'
                    },
                    product_name: 'Salad spinner',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/OXO-Grips-Little-Salad-Spinner/dp/B009KCFHAC/?th=1',
                    },
                },
                {
                    product_brand: 'Nutribullet',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/d67b2d42-3c84-4feb-a34d-18ebeb78b226_blender.jpg?auto=compress,format'
                    },
                    product_name: 'Blender',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/NUTRiBULLET-600-Nutrient-Extractor-Graphite/dp/B01N27W15O/',
                    },
                },
                {
                    product_brand: 'OXO',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/fa2442b6-3e23-4d82-b023-c9edb5a6a304_grater.jpg?auto=compress,format'
                    },
                    product_name: 'Grater',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/OXO-Good-Grips-Box-Grater/dp/B0007VO0CQ/',
                    },
                },
                {
                    product_brand: 'Salter',
                    product_image: {
                        alt: '',
                        dimensions: {
                            height: 192,
                            width: 192,
                        },
                        url: 'https://images.prismic.io/robsimpson/93134b3b-168d-4d17-a37e-198bba33d7fb_kitchenscale.jpg?auto=compress,format'
                    },
                    product_name: 'Kitchen scale',
                    product_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Salter-1009-BKDR-Electronic-Kitchen/dp/B01LZUEEKT/',
                    },
                },
            ],
        },
        apps: {
            overline: 'Apps',
            title: 'Things you can measure, you can improve – my go-to health apps',
            content: [
                {
                    spans: [
                        {
                            start: 0,
                            end: 9,
                            type: "strong",
                        },
                        {
                            start: 125,
                            end: 138,
                            type: "strong",
                        },
                    ],
                    text: 'Headspace is probably my favourite health app – taking a few minutes each day to clear my mind is really helpful. Paired with Apple Health, I can keep track of how I’m doing from meditation to sleep, exercise and more.',
                    type: 'paragraph',
                },
                {
                    spans: [
                        {
                            start: 0,
                            end: 6,
                            type: "strong",
                        },
                        {
                            start: 180,
                            end: 189,
                            type: "strong",
                        },
                    ],
                    text: 'Ignite is what I use to track weight training sessions, it’s a bit old but I’ve been using it for so many years and works for me. If I ever want to intentionally lose weight, I use Lifesum to track what I’m eating, it’s not something I use on the regular but can be useful.',
                    type: 'paragraph',
                },
            ],
            tech: [
                {
                    tech_category: 'Meditation',
                    tech_icon: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2730:6834)"><path d="M31.962 15.815c.372 5.183-2.037 8.124-3.488 10.18-2.258 2.381-5.12 5.627-12.388 5.81-6.165.243-9.194-2.469-11.738-4.823C1.032 23.337.166 21.18 0 15.815c-.015-3.29 1.253-6.573 3.47-9.461C6.58 1.964 11.521.493 16.086.19c4.79-.186 8.6 2.23 11.2 4.832 3.328 3.51 4.35 6.523 4.673 10.793h.003Z"/></g><defs><clipPath id="clip0_2730:6834"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>',
                    tech_name: 'Headspace',
                    tech_url: {
                        link_type: 'Web',
                        url: 'https://www.headspace.com/',
                    },
                },
                {
                    tech_category: 'Workout tracking',
                    tech_icon: '<svg viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg"><path d="M2.533 15.228 19.155.118l-3.733 12.355 14.044 4.71-16.622 14.934 3.911-12.178-14.222-4.71Z"/></svg>',
                    tech_name: 'Ignite',
                    tech_url: {
                        link_type: 'Web',
                        url: 'https://apps.apple.com/gb/app/ignite-workout-tracker/id994843673',
                    },
                },
                {
                    tech_category: 'Food tracking',
                    tech_icon: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M21.245.266c-5.288 0-8.46 3.277-9.814 10.626-.307 1.67-1.169 5.863-1.397 7.173-.785 4.51-1.26 7.232-2.131 8.163-1.021 1.089-2.914 1.912-3.557 2.138l.624 1.789.612 1.792c1.927-.659 3.813-.966 5.936-.966h13.806l.708-3.789h-14.07c.85-1.783 1.301-4.376 2.015-8.477.227-1.302 1.087-5.485 1.39-7.137 1.386-7.523 4.09-7.523 5.878-7.523 1.04 0 1.666.48 2.007.883.462.545.693 1.299.59 1.92-.464 2.772-1.76 3.882-5.42 4.222l-.721 3.858c3.57-.16 8.796-.984 9.878-7.457.287-1.724-.25-3.59-1.436-4.992C24.929 1.055 23.189.266 21.245.266Z"/></svg>',
                    tech_name: 'Lifesum',
                    tech_url: {
                        link_type: 'Web',
                        url: 'https://lifesum.com/',
                    },
                },
                {
                    tech_category: 'Health tracking',
                    tech_icon: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M23.111 1.78A8.868 8.868 0 0 0 16 5.33a8.892 8.892 0 0 0-16 5.34C0 21.315 14.791 29.72 15.42 30.073a1.185 1.185 0 0 0 1.16 0 47.016 47.016 0 0 0 7.62-5.56c5.176-4.659 7.8-9.317 7.8-13.845a8.9 8.9 0 0 0-8.889-8.889Z"/></svg>',
                    tech_name: 'Apple health',
                    tech_url: {
                        link_type: 'Web',
                        url: 'https://www.apple.com/uk/ios/health/',
                    },
                },
            ],
        },
        cta: {
            background: true,
            items: [
                {
                    content_media_alignment: 'rtl',
                    content_media_button_text: {
                        text: 'Browse plant-based recipes',
                    },
                    content_media_button_url: {
                        link_type: 'Web',
                        url: 'https://stryve.life',
                    },
                    content_media_content: {
                        text: 'There’s a lot of conflicting information out there about what’s the optimal diet for humans – but I’ve never seen anyone suggest that vegetables are bad for us, so incorporating more of them into our diet is only a good thing.',
                    },
                    content_media_image: {
                        alt: '',
                        dimensions: {
                            height: 884,
                            width: 1180,
                        },
                        url: 'https://images.prismic.io/robsimpson/a53e3b54-543c-444f-957d-f3bf88751cf0_Listing+Stryve+Small.png?auto=compress,format'
                    },
                    content_media_title: {
                        text: 'Looking for tasty, plant-based recipes?',
                    },
                },
            ],
        },
        books: {
            description: 'Some of my favourite health books that I’ve read over the years which have helped me on my health journey.',
            items: [
                {
                    book_image: {
                        alt: '',
                        dimensions: {
                            height: 240,
                            width: 160,
                        },
                        url: 'https://images.prismic.io/robsimpson/bccf5d69-c88c-406f-be53-8d1710608cf3_why-we-sleep.png?auto=compress,format'
                    },
                    book_rating: 4,
                    book_title: {
                        text: 'Why we sleep',
                    },
                    book_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Why-We-Sleep-Science-Dreams/dp/0141983760/',
                    },
                },
                {
                    book_image: {
                        alt: '',
                        dimensions: {
                            height: 240,
                            width: 160,
                        },
                        url: 'https://images.prismic.io/robsimpson/89ecdb7d-6d3a-41e8-ba9f-c8b97ba37fe6_plant-based-revolution.png?auto=compress,format'
                    },
                    book_rating: 4,
                    book_title: {
                        text: 'The plant-based diet revolution',
                    },
                    book_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Plant-Based-Diet-Revolution-happier-healthier/dp/1529308682',
                    },
                },
                {
                    book_image: {
                        alt: '',
                        dimensions: {
                            height: 240,
                            width: 160,
                        },
                        url: 'https://images.prismic.io/robsimpson/71ef747c-518c-406c-9d43-464ae2dcef14_book-bls.png?auto=compress,format'
                    },
                    book_rating: 3,
                    book_title: {
                        text: 'Bigger leaner stronger',
                    },
                    book_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Bigger-Leaner-Stronger-Building-Ultimate/dp/1938895444/',
                    },
                },
                {
                    book_image: {
                        alt: '',
                        dimensions: {
                            height: 240,
                            width: 160,
                        },
                        url: 'https://images.prismic.io/robsimpson/4d6b5664-75ca-4adf-8a18-db8506765b03_optimum-nutrition-bible.png?auto=compress,format'
                    },
                    book_rating: 4,
                    book_title: {
                        text: 'The optimum nutrition bible',
                    },
                    book_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Optimum-Nutrition-Bible-About-Health/dp/0749925523/',
                    },
                },
                {
                    book_image: {
                        alt: '',
                        dimensions: {
                            height: 240,
                            width: 160,
                        },
                        url: 'https://images.prismic.io/robsimpson/ffd171e2-1ca4-4551-9e4f-fe62a059d52d_plant-therapy.png?auto=compress,format'
                    },
                    book_rating: 3,
                    book_title: {
                        text: 'Plant therapy',
                    },
                    book_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/Plant-Therapy-improve-emotional-wellbeing/dp/1784883522',
                    },
                },
                {
                    book_image: {
                        alt: '',
                        dimensions: {
                            height: 240,
                            width: 160,
                        },
                        url: 'https://images.prismic.io/robsimpson/5b18f0ac-be11-4d44-9310-4a4f8d1ed04f_super-life.png?auto=compress,format'
                    },
                    book_rating: 3,
                    book_title: {
                        text: 'SuperLife',
                    },
                    book_url: {
                        link_type: 'Web',
                        url: 'https://www.amazon.co.uk/SuperLife-Simple-Healthy-Eternally-Awesome/dp/0062297198',
                    },
                },
            ],
            title: 'Favourite health books',
        },
    };

    return (
        <Shell
            pageDescription={data.masthead.description[0].text}
            pageSocialImage="https://images.prismic.io/robsimpson/c016eb23-c453-4ed2-ba61-27baf94245c2_Social+Share+Health.jpg"
            pageTitle={data.masthead.title}
            pathname={pathname}
        >
            <SubHeader
                navLinkTitle="Explore"
                navLinks={[
                    {
                        nav_link_text: {
                            text: 'Exercise',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#exercise',
                        },
                    },
                    {
                        nav_link_text: {
                            text: 'Supplements',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#supplements',
                        },
                    },
                    {
                        nav_link_text: {
                            text: 'Drinks',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#drinks',
                        },
                    },
                    {
                        nav_link_text: {
                            text: 'Hygiene',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#hygiene',
                        },
                    },
                    {
                        nav_link_text: {
                            text: 'Cooking',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#cooking',
                        },
                    },
                    {
                        nav_link_text: {
                            text: 'Apps',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#apps',
                        },
                    },
                    {
                        nav_link_text: {
                            text: 'Books',
                        },
                        nav_link_url: {
                            link_type: 'Document',
                            uid: 'health#favourite-health-books',
                        },
                    },
                ]}
                navCtaEmoji="🍏"
                navCtaText="Plant-based recipes"
                navCtaUrl={{
                    link_type: 'Web',
                    url: 'https://stryve.life',
                }}
            />
            <Masthead
                description={data.masthead.description}
                image={data.masthead.image}
                subheader={true}
                title={data.masthead.title}
            />
            {/* <BlogList
                items={data.blog.items}
            /> */}
            <ProductBlock
                overline={data.exercise.overline}
                title={data.exercise.title}
                content={data.exercise.content}
                media={data.exercise.media}
                products={data.exercise.products}
            />
            <ProductBlock
                overline={data.supplements.overline}
                title={data.supplements.title}
                content={data.supplements.content}
                media={data.supplements.media}
                products={data.supplements.products}
            />
            <ProductBlock
                overline={data.drinks.overline}
                title={data.drinks.title}
                content={data.drinks.content}
                media={data.drinks.media}
                products={data.drinks.products}
            />
            <ProductBlock
                overline={data.hygiene.overline}
                title={data.hygiene.title}
                content={data.hygiene.content}
                media={data.hygiene.media}
                products={data.hygiene.products}
            />
            <ProductBlock
                overline={data.cooking.overline}
                title={data.cooking.title}
                content={data.cooking.content}
                media={data.cooking.media}
                products={data.cooking.products}
            />
            <TechBlock
                overline={data.apps.overline}
                title={data.apps.title}
                content={data.apps.content}
                tech={data.apps.tech}
            />
            <ContentMediaListBlock
                background={data.cta.background}
                items={data.cta.items}
            />
            <BookListBlock
                description={data.books.description}
                items={data.books.items}
                title={data.books.title}
            />
        </Shell>
    );
}

export default HealthLandingPage;
